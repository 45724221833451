<!-- System: STA
    Purpose: User can see his/her Tasks/Activities like
            time spent, status, update , add manual time etc.
-->

<template>
  <v-app>
    <!-- Dummy Page For New User -->
    <DummyTask
      v-if="
        selectedCompanyUserStatus != 'employee' &&
        selected_company.last_working_date == null &&
        active_team_members_list.length <= 1
      "
    />
    <!-- Activites Page -->
    <div v-else>
      <!-- Date Range Picker -->
      <v-row class="mt-2 d-flex justify-end" @click="menu = false">
        <v-col cols="12" sm="6" md="3">
          <label
            class="d-flex labels ml-4 font-weight-thin text-left mt-n1"
            for
            id="cRange"
            >Date Range:</label
          >
          <v-menu nudge-left="0" :close-on-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-col class="d-flex flex-row-reverse">
                <v-subheader
                  class="selected-date mr-3"
                  style="width: 100%"
                  v-bind="attrs"
                  v-on="on"
                  @click="checkClick"
                >
                  {{ dateRangeTitle }}
                  <span class="d-flex justify-start ml-auto">
                    <v-icon id="rangeBtn" color="#2758F6"
                      >mdi-calendar-range</v-icon
                    >
                  </span>
                </v-subheader>
              </v-col>
            </template>
            <v-list id="custom-list-style">
              <v-list-item
                style="min-height: 30px"
                v-for="(item, index) in items"
                :key="index"
                @click="checkIfCustomDate(item)"
              >
                <v-list-item-title style="font-size: 13px" class="custom">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog
            ref="dialog"
            v-model="modal_2"
            :return-value.sync="date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-show="(modal_2_show = false)"
                v-model="date"
                label="Picker in dialog"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="closeDateModal()">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                id="apply-btn"
                @click="getTaskFromCustomDate()"
              >
                Apply
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <!---------------------- Activites Table ---------------------->
      <v-row class="ml-2 mr-2">
        <v-col cols="12" sm="12" md="12">
          <template>
            <v-data-table
              :headers="headers"
              :current-page="currentPage"
              :items-per-page="perPage"
              :items="tasks"
              class="elevation-1"
              id="taskListingTable"
              @click:row="openDetails"
              hide-default-footer
            >
              <!---------------------- Headers ---------------------->
              <template
                v-if="$vuetify.breakpoint.width > 600"
                v-slot:header="{ props }"
              >
                <!------------- Sorting Header Start ------------------->
                <tr>
                  <th
                    v-for="field in props.headers"
                    :class="field.value + '_header'"
                    class="header-table"
                    :key="field.value"
                  >
                    <span>
                      {{ field.text }}
                      <span
                        v-if="
                          field.value != 'worked_time' &&
                          field.value != 'due_date' &&
                          field.value != 'actions' &&
                          field.value != 'member.last_worked_at' &&
                          field.value != 'status' &&
                          sort.column == field.value &&
                          sort.order_by == 'desc'
                        "
                        style="cursor: pointer"
                      >
                        <span
                          class="mdi mdi-arrow-down"
                          @click="sortData(field.value, 'asc')"
                        ></span>
                      </span>
                      <span v-else style="cursor: pointer">
                        <span
                          class="mdi mdi-arrow-up"
                          v-if="
                            field.value != 'worked_time' &&
                            field.value != 'due_date' &&
                            field.value != 'actions' &&
                            field.value != 'member.last_worked_at' &&
                            field.value != 'status'
                          "
                          @click="sortData(field.value, 'desc')"
                        ></span>
                      </span>
                    </span>
                  </th>
                </tr>
                <!--------------- Sorting Header End   -------------------->
                <!--------------- Filter Header Start --------------------->
                <td
                  v-for="field in props.headers"
                  :class="field.value + '_selectBox'"
                  :key="field.value"
                  style="
                    padding-top: 20px;
                    padding-left: 5px;
                    box-shadow: 0 25px 35px -20px rgba(172, 184, 207, 0.34);
                  "
                >
                  <v-autocomplete
                    outlined
                    dense
                    v-if="field.value == 'project_name'"
                    v-model="searchData.project_name_key"
                    :items="projectList"
                    item-text="project_name"
                    item-value="id"
                    label="Search By Project"
                  ></v-autocomplete>
                  <v-autocomplete
                    outlined
                    dense
                    v-if="
                      field.value == 'members_name' &&
                      (selectedCompanyUserStatus !== 'employee' ||
                        lead_team_members.length > 1)
                    "
                    v-model="searchData.members_name"
                    :items="filteredDataget"
                    :loading="teamMemberLoader"
                    item-text="name"
                    item-value="id"
                    label="Search By Member"
                  ></v-autocomplete>
                  <v-autocomplete
                    outlined
                    dense
                    v-if="field.value == 'assigned_by_name'"
                    v-model="searchData.assigned_by_name"
                    :items="filteredDataget"
                    item-text="name"
                    item-value="id"
                    label="Search By Team Member"
                  ></v-autocomplete>
                  <v-text-field
                    id="search"
                    dense
                    outlined
                    autocomplete="off"
                    v-model="searchData.title"
                    ref="search"
                    color="primary"
                    v-if="field.value == 'title'"
                    label="Search in Title"
                  ></v-text-field>
                  <v-select
                    v-if="field.value == 'status'"
                    :items="options"
                    dense
                    outlined
                    v-model="searchData.status"
                  >
                  </v-select>
                </td>
                <!--------------- Filter Header End --------------------->
              </template>

              <!---------------------- Assign Members ---------------------->
              <template v-slot:item.members_name="{ item }">
                <span style="text-transform: capitalize">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        :src="`${$api_url}storage/${item.members.image}`"
                        @error="
                          $event.target.src = require('../assets/images/no-member.svg')
                        "
                        alt="no image"
                        class="select-image mb-1"
                      />
                      <span
                        style="position: relative; top: -6px; left: 11px"
                        v-bind="attrs"
                        v-on="on"
                        >{{
                          trim_member_name(
                            item.members.name,
                            item.members.email
                          )
                        }}</span
                      >
                    </template>
                    <span
                      >{{
                        item.members.name
                          ? item.members.name
                          : item.members.email
                      }}
                    </span>
                  </v-tooltip>
                </span>
              </template>

              <!---------------------------- project  ----------------------->
              <template v-slot:item.project_name="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="text-capitalize" v-bind="attrs" v-on="on">
                      {{ trim_task_project(item.project_name) }}
                    </span>
                  </template>
                  <span>{{ item.project_name }}</span>
                </v-tooltip>
              </template>

              <!----------------------------  Task Title -------------------->
              <template v-slot:item.title="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="file-name d-inline title-team-member">
                        <span>{{ trim_task_title(item.title) }}</span>
                      </div>
                    </div>
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
              </template>

              <!---------------------------- Total Working ------->
              <template v-slot:item.worked_time="{ item }">
                <span class="d-flex justify-center">
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          :title="item.members.name + '`s working time '"
                          v-if="item.members.total_worked_time != 0"
                        >
                          {{
                            convert_mins_to_hour(item.members.total_worked_time)
                          }}
                        </span>
                      </template>
                      <span>
                        {{ dateFormate(item.members.last_worked_at) }}
                      </span>
                    </v-tooltip>
                    <br />
                  </span>
                </span>
              </template>

              <!---------------------------- Task Status ---------->
              <template v-slot:item.status="{ item }">
                <span class="d-flex justify-center">
                  <v-btn
                    class="mx-1 white--text text-center"
                    style="max-width: 83% !important"
                    x-small
                    :color="
                      item.status === 'pending'
                        ? '#ffc107'
                        : item.status === 'active'
                        ? 'primary'
                        : '#28a745'
                    "
                  >
                    {{ item.status }}
                  </v-btn>
                </span>
              </template>

              <!---------------------------- Actions -------------->
              <template
                span="1"
                v-slot:item.actions="{ item }"
                v-if="items.length > 0"
              >
                <div
                  class="tsk-action-div d-flex justify-center"
                  @click.stop="addManualTimeModal(item)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-if="
                          selectedCompany.manual_time_allowed &&
                          selectedCompanyUserStatus !== 'employee'
                        "
                        class="pl-2 manual-icon"
                        src="../assets/images/dashboard_icons/create_project_button.svg"
                        title="Add Manual Time"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span> Add Manual Time </span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <v-pagination
              class="count-paginatin-task float-right"
              v-model="currentPage"
              :length="paginationLength(pagination.total)"
            >
            </v-pagination>
          </template>
        </v-col>
      </v-row>
      <!----------------------- Components Import ---------------->
      <updateTaskModal />
      <addManualTimeModal />
    </div>
  </v-app>
</template>
<script>
import moment from "moment";
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";
import { min_to_hour } from "@/assets/js/utils";
import _ from "lodash";
export default {
  name: "tasks",
  components: {
    updateTaskModal: () => import("../components/tasks/UpdateTaskModal"),
    addManualTimeModal: () => import("../components/tasks/AddManualTimeModal"),
    DummyTask: () => import("@/views/DummyPages/DummyTask"),
  },
  data() {
    return {
      modal_2: false,
      date_loader: false,
      teamMemberLoader: false,
      date: [],
      showPicker: false,
      menu: false,
      filteredData: [],
      pagination: [],
      items: [
        { id: 1, title: "Today" },
        { id: 2, title: "Yesterday" },
        { id: 3, title: "Last 7 Days", value: "" },
        { id: 4, title: "Last 30 Days", value: "" },
        { id: 5, title: "This Month", value: "" },
        { id: 6, title: "Last Month", value: "" },
        { id: 7, title: "Last Year", value: "" },
        { id: 8, title: "Custom Range" },
      ],
      options: [
        { value: "all", text: "All" },
        { value: "pending", text: "Pending" },
        { value: "active", text: "Active" },
        { value: "completed", text: "Completed" },
      ],
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
      filter: "by_projects",
      loader: true,
      dateRangeTitle: "",
      headers: [],
      tasks: [],
      currentPage: 1,
      perPage: 100,
      sort: {
        column: "created_at",
        order_by: "desc",
      },
      searchData: {
        project_name_key: "",
        members_name: "",
        assigned_by_name: "",
        title: "",
        status: "all",
        column: "",
        order_by: "desc",
      },
    };
  },
  computed: {
    ...mapState("custom", [
      "companies_list",
      "selected_company",
      "tasks_current_tab",
      "projects_list",
      "project_task",
      "active_team_members_list",
      "lead_team_members",
    ]),
    columnOrder() {
      return this.order_by;
    },
    columnName() {
      return this.column_name;
    },
    API_URL() {
      return baseURL.API_URL;
    },
    /**
     * Return the projects lsit of that user.
     */
    projectList() {
      let arrayPro = [];
      let allproject = {
        id: "",
        project_name: "All Projects",
      };
      arrayPro.push(allproject);
      this.projects_list.active.map((project) => {
        let newProject = {
          id: project.id,
          project_name: project.project_name,
        };
        arrayPro.push(newProject);
      });
      return arrayPro;
    },
    /**
     * Return the team members list.
     */
    filteredDataget() {
      let all = {
        id: "all",
        name: "All Members",
      };
      this.filteredData.unshift(all);
      let auth_add = this.filteredData.find(
        (m) => m.id == this.$store.state.auth.user.id
      );
      if (!auth_add) {
        let employer = {
          id: this.$store.state.auth.user.id,
          name: this.$store.state.auth.user.name,
          email: this.$store.state.auth.user.email,
        };
        this.filteredData.push(employer);
        return this.filteredData;
      }
      return this.filteredData;
    },
    selectedCompanyUserHasTeam() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    selectedCompany() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
  },
  mounted() {
    /**
     * Custom event to update title
     */
    this.$eventBus.$on("update_title", (payload) => {
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].id == payload.task_id) {
          this.tasks[i].title = payload.title;
        }
      }
    });
    /**
     * Custom event to update description
     */
    this.$eventBus.$on("update_description", (payload) => {
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].id == payload.task_id) {
          this.tasks[i].description = payload.description;
        }
      }
    });
    /**
     * Custom event to update Due date
     */
    this.$eventBus.$on("update_due_date", (payload) => {
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].id == payload.task_id) {
          this.tasks[i].due_date = payload.due_date;
        }
      }
    });
    /**
     * Custom event to update status
     */
    this.$root.$on("taskStatusUpdate", (task) => {
      let data = {
        search: this.searchData,
        per_page: this.perPage,
      };
      this.update_status(task, data);
    });

    window.addEventListener("click", () => {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "none") {
        document.getElementById("rangeBtn").click();
        x.style.display = "none";
      }
    });

    this.dateRangeTitle =
      moment().startOf("month").format("MMM D, YYYY") +
      "-" +
      moment().format("MMM D, YYYY");
    this.getTasksData();
    if (
      this.selectedCompanyUserStatus === "employer" ||
      this.selectedCompanyUserHasTeam ||
      this.selectedCompanyUserStatus === "secondary_user"
    ) {
      this.headers = [
        {
          value: "members_name",
          text: "Team Member",
          sortable: true,
          class: "assign-by",
        },
        {
          value: "project_name",
          text: "Project Name",
          sortable: true,
          sortDirection: "desc",
          class: "project-name",
        },

        {
          value: "title",
          text: "Title",
          sortable: true,
          sortDirection: "desc",
          class: "title-td",
        },
        { value: "worked_time", text: "Spent Time", class: "text-center" },
        { value: "status", text: "Task Status", class: "text-center" },
        { value: "actions", text: "Actions", class: "action-td" },
      ];
    } else {
      this.headers = [
        {
          value: "members_name",
          text: "Team Member",
          sortable: true,
          class: "assign-by",
        },
        {
          value: "project_name",
          text: "Project Name",
          sortable: true,
          sortDirection: "desc",
          class: "project-name",
        },
        {
          value: "title",
          text: "Title",
          sortable: true,
          sortDirection: "desc",
          class: "title-td",
        },
        { value: "worked_time", text: "Spent Time", class: "text-center" },
        { value: "status", text: "Task Status", class: "text-center" },
        { value: "actions", text: "", class: "action-td" },
      ];
    }
    if (
      this.selectedCompanyUserStatus !== "employee" ||
      this.lead_team_members.length > 1
    ) {
      this.getTeamMembers();
    }
    if (this.$store.state.custom.projects_list.active.length == 0) {
      this.getProjects();
    }
  },
  watch: {
    pagination: {
      handler: function () {
        this.perPage = parseInt(this.pagination.per_page);
      },
    },
    "searchData.project_name_key": {
      handler: _.debounce(function () {
        this.getTasksData();
      }, 1000),
      deep: true,
    },
    "searchData.assigned_by_name": {
      handler: _.debounce(function () {
        this.getTasksData();
      }, 1000),
      deep: true,
    },
    "searchData.title": {
      handler: _.debounce(function () {
        this.getTasksData();
      }, 1000),
      deep: true,
    },

    "searchData.members_name": {
      handler: _.debounce(function () {
        this.getTasksData();
      }, 1000),
      deep: true,
    },
    "searchData.status": {
      handler: _.debounce(function () {
        this.getTasksData();
      }, 1000),
      deep: true,
    },
    currentPage() {
      this.getTasksData();
    },
  },
  methods: {
    /**
     * This function is resposible for
     * fetching the projects list
     */
    getProjects() {
      this.$store.dispatch("custom/getProjects", {
        company_id: this.$route.params.id,
      });
    },

    paginationLength(total) {
      let paginationLength = 0;
      if (total > 0) {
        paginationLength = Math.ceil(total / this.perPage);
      }
      return paginationLength;
    },
    /**
     * This function is resposible for opening
     * task update modal and pass the data to
     * update task component
     */
    openDetails(row) {
      let task_id = row.id;
      this.task_comment_remove = row.id;
      let getTask = this.project_task;
      // getTask = this.project_task.map((data) => {
      //   var temp = Object.assign({}, data);
      //   if (data.id === task_id) {
      //     temp.commentss.length = null;
      //   }
      //   return temp;
      // });
      this.$store.commit("custom/set_task_members", row.members);
      this.currentTask = row;
      this.$eventBus.$emit("taskUpdater", row);
    },
    /**
     * This function is resposible for update
     * the task status , api call and store
     * update
     */
    update_status(task_id, getsearch) {
      let data = {
        company_id: this.$route.params.id,
        task_id: task_id,
      };
      this.$store
        .dispatch("custom/update_status", data)
        .then((response) => {
          if (response.data.task.completed == true) {
            let notifyData = {
              company_id: this.$route.params.id,
              task_id: response.data.task.id,
              completed: response.data.task.completed,
              assigned_by_id: response.data.task.assigned_by_id,
              message: `${this.$store.state.auth.user.name} has completed ${response.data.task.title}`,
            };
            this.$socket.emit("task_completed", notifyData);
          }
          if (response.data.error) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            for (let i = 0; i < this.tasks.length; i++) {
              if (this.tasks[i].id == response.data.task.id) {
                this.tasks[i].completed = response.data.task.completed;
                if (response.data.task.completed == true) {
                  this.tasks[i].status = "completed";
                } else {
                  this.tasks[i].status = "active";
                }
              }
            }
            this.$root.$emit("task_list_updated_status", getsearch, true);
          }
        })
        .catch(() => {});
    },
    checkClick() {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    closeDateModal() {
      this.modal_2 = false;
      this.menu = false;
    },
    /**
     * This function is resposible for trigger
     * add manual time modal and pass the data
     * to add manual time component
     */
    addManualTimeModal(task) {
      this.$root.$emit("add-manual-time", task);
    },
    /**
     * This function is resposible for fetching
     * tasks w.r.t custom range filter.
     */
    getTaskFromCustomDate() {
      this.modal_2 = false;
      this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
      this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      if (this.start_date.toString() === "Invalid date") {
        let date = this.end_date.split("-");
        let year = date[0];
        let month = date[1];
        this.start_date = year + "-" + month + "-01";
        this.date[0] = year + "-" + month + "-01";
      }
      this.dateRangeTitle =
        moment(this.date[0]).format("ll") +
        " - " +
        moment(this.date[1]).format("ll");
      if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
        this.dateRangeTitle =
          moment(this.date[1]).format("ll") +
          " - " +
          moment(this.date[0]).format("ll");
      }
      if (this.end_date < this.start_date) {
        this.start_date = this.start_date
          ? this.start_date
          : moment(this.date[1]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[0]).format("YYYY-MM-DD");
      } else if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
      }
      this.date_loader = true;
      this.getTasksData(this.start_date, this.end_date);
      this.date = "";
      this.menu = false;
    },
    /**
     * This function is resposible for fetching
     * team members list.
     */
    getTeamMembers: async function () {
      if (this.$store.state.custom.get_team_members_list.length > 1) {
        this.filteredData = this.$store.state.custom.team_members;
      } else {
        this.teamMemberLoader = true;
        let data = await this.$store
          .dispatch("custom/get_team_members", {
            company_id: this.$route.params.id,
          })
          .then(() => {
            this.teamMemberLoader = false;
          })
          .catch(() => {
            this.teamMemberLoader = false;
          });
        this.filteredData = data.team_members;
      }
    },
    /**
     * This function is resposible for getting
     * current date
     */
    getCurrentDate() {
      let date = new Date();
      this.showCurrentDate = moment(date).format("MMM D, YYYY");
      return this.showCurrentDate;
    },
    /**
     * This function is resposible for
     * formatting date
     */
    dateFormate(date) {
      return moment(date).format("MMM D, YYYY");
    },
    /**
     * This function is resposible for
     * trim task title
     */
    trim_task_title(task_title) {
      return task_title.length > 50
        ? task_title.slice(0, 50) + "..."
        : task_title;
    },
    /**
     * This function is resposible for
     * trim projects name
     */
    trim_task_project(task_project) {
      return task_project.length > 25
        ? task_project.slice(0, 25) + "..."
        : task_project;
    },
    /**
     * This function is resposible for
     * trim maember name
     */
    trim_member_name(member_name, email) {
      if (member_name) {
        return member_name.length > 25
          ? member_name.slice(0, 25) + "..."
          : member_name;
      } else if (email) {
        return email.length > 25 ? email.slice(0, 25) + "..." : email;
      }
    },
    convert_mins_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hour = time.hrs.toString().padStart(2, "0");
      let mins = time.mins.toString().padStart(2, "0");
      return hour + ":" + mins;
    },
    sortData(column_name, order_by) {
      this.column_name = column_name;
      this.order_by = order_by;
      let sort = {
        column: column_name,
        order_by: order_by,
      };
      this.sort.column = column_name;
      this.sort.order_by = order_by;
      this.getTasksData(sort);
    },
    /**
     * This function is resposible for custom
     * date range selection
     */
    checkIfCustomDate(date) {
      this.date_loader = true;
      date.value =
        moment().subtract(6, "days").format("MMM D, YYYY") +
        "-" +
        moment().format("MMM D, YYYY");
      this.dateRangeTitle = date.value;
      if (date.id === 1) {
        let start = moment().format("YYYY-MM-DD");
        let end = moment().format("YYYY-MM-DD");
        this.dateRangeTitle = "Today";
      }
      if (date.id === 2) {
        let start = moment().subtract(1, "days").format("YYYY-MM-DD");
        let end = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitle = "Yesterday";
      }
      if (date.id === 3) {
        date.value =
          moment().subtract(6, "days").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 4) {
        date.value =
          moment().subtract(29, "days").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 5) {
        date.value =
          moment().startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().endOf("month").format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 6) {
        date.value =
          moment().subtract(1, "month").startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().subtract(1, "month").endOf("month").format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 7) {
        date.value =
          moment().subtract(1, "year").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 8) {
        this.modal_2 = true;
        document.getElementById("cRange").click();
        // this.showPicker = true;
        // this.dateRangeTitle = "Custom Range";
        // document.getElementById("range-date").click();
      } else {
        this.showPicker = false;
        this.setDates(date);
        if (this.getSearchData == "") {
          this.getTasksData();
        } else {
          this.getTasksData(true, null, this.getSearchData, 99, null);
        }
        // this.$root.$emit('search_date_range',true)
      }
      document.getElementById("cRange").click();
    },

    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (date.id === 3) {
        this.start_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 4) {
        this.start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 5) {
        this.start_date = moment().startOf("month").format("YYYY-MM-DD");
        this.end_date = moment().endOf("month").format("YYYY-MM-DD");
      } else if (date.id === 6) {
        this.start_date = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.end_date = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (date.id === 7) {
        this.start_date = moment().subtract(1, "year").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      }
    },
    /**
     * This function is resposible for
     * getting tasks data for table
     */
    getTasksData: _.debounce(
      function (sort = null) {
        let data = true;
        let search_data = this.searchData;
        if (search_data != null && search_data.members_name != "") {
          this.selectedMember = search_data.members_name;
        } else {
          this.selectedMember = "all";
        }
        if (search_data != null && search_data.project_name_key != "") {
          this.selectedProject = search_data.project_name_key;
        } else {
          this.selectedProject = "all";
        }
        if (sort == null) {
          sort = {
            column: "created_at",
            order_by: "desc",
          };
        }
        if (this.selectedMember == null || this.selectedMember == "") {
          this.selectedMember = "all";
        }
        let obj = {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          project_id: this.selectedProject,
          company_id: this.$route.params.id,
          start_date: this.start_date,
          end_date: this.end_date,
          member_id: this.selectedMember,
          tasks_tab: this.tasks_current_tab,
          search_data: search_data,
          page: this.currentPage,
          per_page: this.perPage,
          sort: sort,
        };
        if (this.search_laoder) {
          this.$store.commit("custom/toggle_loader", true);
        }
        if (this.$store.state.custom.project_task) {
          this.tasks = this.$store.state.custom.project_task;
        }
        if (this.date_loader) {
          this.$store.commit("custom/toggle_loader", true);
        }
        this.$store.commit("custom/toggle_loader", true);
        this.$store
          .dispatch("custom/project_task_list", { obj, data })
          .then((response) => {
            this.taskLoader = true;
            this.$store.commit("custom/toggle_loader", false);
            this.search_laoder = false;
            this.date_loader = false;
            this.pagination = response.data.data.paginate;
            this.tasks = _.orderBy(
              response.data.data.tasks,
              ["comments"],
              ["desc"]
            );
            this.myProjects = response.data.data.projects;
            this.$store.commit("custom/set_project_task_list", this.tasks);
          })
          .catch((error) => {
            this.$store.commit("custom/toggle_loader", false);
            this.search_laoder = false;
            this.date_loader = false;
            this.taskLoader = true;
          });
      },
      2000,
      { leading: true, trailing: false }
    ),
  },
};
</script>
<style scoped>
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}
.calender-btn {
  width: 20px !important;
  z-index: 0.9 !important;
  align-content: right !important;
  margin-left: -0px;
}
.selected-date {
  border: 1px solid rgba(54, 69, 79, 0.32) !important;
  font-size: 12px !important;
  display: flex !important;
  height: 35px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}

.select-image {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.btn-more {
  border: none;
  text-decoration: underline;
  margin-top: 7px;
  font-size: 10px;
  color: #2758f6 !important;
}
.select-image-pop {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 2px;
}

.status_selectBox {
  max-width: 25px !important;
}

.assigned_by_name_header,
.project_name_header,
.title_header {
  text-align: left;
}

.assigned_by_name_header > span,
.project_name_header > span,
.title_header > span {
  margin-left: 10px;
}

#taskListingTable th.header-table {
  border-bottom: 1px solid #d1ddf0;
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}
.sorting-cursor {
  cursor: pointer;
  height: 0px;
}
</style>
<style>
#taskListingTable td:last-of-type {
  width: 0px !important;
  -webkit-box-shadow: 0px 0px 0px -15px rgba(0, 0, 0, 0.65) !important;
  box-shadow: 0px 0px 0px -15px rgba(0, 0, 0, 0.65) !important;
}
#taskListingTable thead tr:nth-child(2) {
  display: none !important;
}
#taskListingTable th:last-of-type {
  right: 0;
  -webkit-box-shadow: 0 0px 0px 0 rgba(221, 221, 221, 0.5) !important;
  box-shadow: 0 0px 0px 0 rgba(221, 221, 221, 0.5) !important;
}
#taskListingTable td.member-name {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 30px !important;
}
#taskListingTable td.assign-by {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 30px !important;
}

#taskListingTable thead.v-data-table-header tr:nth-child(1) {
  display: none !important;
}
</style>
